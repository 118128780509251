import React from "react"
import { withTrans } from "../../i18n/withTrans"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import i18next, { t } from "i18next"
import "./cookiesPolicy.scss"
import { printToLetter } from "../../helpers/numericToAlphabet"

const CookiesPolicy = () => {
  const content = i18next.language == "id" ? cookiesContentId : cookiesContentEn

  return (
    <Layout>
      <SEO title={t("footer.text-cookies-policy")} />

      <div className="header-cookies-policy">
        <div className="header-title">{t("cookies.cookies-header")}</div>
        <div
          className="header-subtitle"
          dangerouslySetInnerHTML={{ __html: t("cookies.cookies-subtitle") }}
        />
      </div>
      <div className="cookies-policy-content">
        {content.map((e, index) => (
          <div className="section">
            <tr>
              {index == 0 ? (
                <td>
                  <h5
                    className={"title"}
                    dangerouslySetInnerHTML={{ __html: e.title }}
                  />
                </td>
              ) : (
                <>
                  <td>
                    {index === 0 ? (
                      <></>
                    ) : (
                      <h5 className={index == 0 ? "title" : "subtitle"}>
                        {index}.
                      </h5>
                    )}
                  </td>
                  <td></td>
                  <td>
                    <h5
                      className={index == 0 ? "title" : "subtitle"}
                      dangerouslySetInnerHTML={{ __html: e.title }}
                    />
                  </td>
                </>
              )}
            </tr>

            {typeof e.description === "object" ? (
              e.description.map(el => (
                <>
                  {typeof el === "object" ? (
                    el.isTitle ? (
                      <p dangerouslySetInnerHTML={{ __html: el.title }} />
                    ) : (
                      <tr>
                        <td>
                          <p> {el.sequence}.</p>
                        </td>
                        <td></td>
                        <td>
                          {el.description.length === 0 ? (
                            <p dangerouslySetInnerHTML={{ __html: el.title }} />
                          ) : (
                            <>
                              <p
                                dangerouslySetInnerHTML={{ __html: el.title }}
                              />
                              {el.description.map((subEl, idx) => (
                                <tr>
                                  <td>
                                    <p> {printToLetter(idx).toLowerCase()}.</p>
                                  </td>
                                  <td></td>
                                  <td>
                                    {typeof subEl === "object" ? (
                                      <>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: subEl.title,
                                          }}
                                        />
                                        {subEl.description.map((item, i) => (
                                          <tr>
                                            <td>{i + 1}.</td>
                                            <td></td>
                                            <td>
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: item,
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    ) : (
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: subEl,
                                        }}
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  ) : (
                    <p dangerouslySetInnerHTML={{ __html: el }} />
                  )}
                </>
              ))
            ) : (
              <p dangerouslySetInnerHTML={{ __html: e.description }} />
            )}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default withTrans(CookiesPolicy)

const cookiesContentId = [
  {
    title: "Tentang Cookies",
    description:
      "Cookies merupakan file teks kecil yang tersimpan pada komputer atau perangkat anda. Kami menggunakan cookies untuk melacak aktivitas pengguna untuk meningkatkan layanan interface dan pengalaman pelanggan. Perangkat dan browser internet pada umumnya menggunakan cookies; merupakan kebijakan kami bahwa penggunaan cookies dan teknologi tracking digunakan hanya dengan persetujuan pelanggan pada layanan digital yang kami berikan, kecuali dimana penggunaan cookies atau teknologi tracking sangat diperlukan untuk pelaksanaan dan penggunaan layanan.<br/><br/>Kebijakan ini menjelaskan bagaimana AsetKu menggunakan cookies sehubungan dengan operasi kami pada situs web <span><a className='link-text' target='_blank' href='https://www.asetku.co.id'>www.asetku.co.id</a></span> dan seluruh situs web dan aplikasi kami <strong>(“Layanan”)</strong>.<br/><br/>Kami akan menjabarkan informasi terkait:<br/>Cookies dan teknologi online tracking<br/>Bagaimana kami menggunakan cookies dan teknologi online tracking<br/>Bagaimana menghubungi kami.",
  },
  {
    title: "Cookies",
    description:
      "Cookie merupakan file teks kecil yang dikirimkan oleh suatu situs web kepada komputer atau perangkat anda dan disimpan pada browser situs web anda. Browser situs web cookies dapat menyimpan informasi seperti IP Address, jenis browser, dan informasi terkait konten yang anda lihat atau berinteraksi dengan layanan digital. Dengan menyimpan informasi tersebut, browser situs web cookies dapat mengingat preferensi anda dan pengaturan anda untuk layanan online dan bagaimana anda menggunakan layanan online.",
  },
  {
    title: "Bagaimana Kami Menggunakan Cookies Dan Teknologi Online Tracking",
    description: [
      "Kami menggunakan beberapa cookies dan teknologi tracking untuk tujuan yang benar-benar diperlukan untuk penggunaan anda atas Layanan kami. Termasuk, antara lain, cookies yang mengingat preferensi dan pengaturan anda; yang mengingat informasi yang mungkin anda masukkan secara online (sehingga tidak harus dimasukkan kembali setiap kali anda pindah ke halaman lain); atau untuk membuat anda tetap masuk ke portal yang mungkin kami tawarkan di portal yang anda inginkan.<br/><br/>",
      "Dengan persetujuan anda, kami juga dapat menggunakan cookies dan teknologi tracking untuk tujuan lain, seperti pembuatan statistik agregat tentang cara orang menggunakan Layanan kami; untuk penanggulangan kerusakan teknis (error) dan pemecahan masalah (troubleshooting); untuk membantu anda berbagi konten dengan orang lain; untuk memungkinkan anda menerima informasi tentang produk dan layanan lain, dan untuk meningkatkan pengalaman Layanan Anda.<br/><br/>",
      'Strictly Necessary Cookies<br/>Cookies ini diperlukan agar fitur inti situs kami dapat beroperasi dengan baik. Karena mereka diperlukan untuk operasi situs, pengaturan Strict Necessary Cookies selalu dipasang dalam keadaan "Aktif". Anda dapat menonaktifkannya dengan mengubah pengaturan browser anda, tetapi ini dapat mempengaruhi fungsi situs web.<br/><br/>',
      "Functional Cookies<br/>Cookies ini digunakan untuk memberikan pengalaman pengguna yang lebih baik di situs web kami, seperti dengan mengukur interaksi dengan konten tertentu atau mengingat pengaturan anda seperti preferensi bahasa atau pemutaran video.<br/><br/>",
      "Performance Cookies<br/>Cookies ini memungkinkan kami menghitung kunjungan dan sumber lalu lintas pada situs web sehingga kami dapat mengukur dan meningkatkan kinerja situs web kami. Cookies ini membantu kami mengetahui halaman mana yang paling dan paling tidak populer dan melihat bagaimana pengunjung bergerak di sekitar situs. Semua informasi yang dikumpulkan cookies ini adalah agregat dan anonim. Jika anda tidak mengizinkan cookies ini, kami tidak akan mengetahui kapan anda mengunjungi situs web kami, dan tidak akan dapat memantau kinerja situs web kami.<br/><br/>",
      "Jika Anda telah mengizinkan penggunaan performance cookies, kami menggunakan Google Analytics untuk mengumpulkan informasi statistik tentang bagaimana Layanan kami digunakan. Layanan Google Analytics melibatkan penggunaan cookie yang mengumpulkan informasi seperti informasi browser, dan informasi tentang konten yang anda lihat dan berinteraksi untuk mencatat cara anda menggunakan Layanan kami.<br/><br/>",
      "Layanan analitik ini membantu kami mengetahui berapa banyak pengguna yang kami miliki, bagian mana dari situs kami yang paling populer, browser apa yang digunakan (sehingga kami dapat memaksimalkan kompatibilitas), negara atau wilayah tempat pengguna kami berada, dan demografi serta minat pengguna kami. Hal ini memungkinkan kami untuk lebih memahami siapa yang menggunakan Layanan kami dan untuk meningkatkan cara kami menyajikan konten di Layanan.<br/><br/>",
      "Untuk informasi lebih lanjut tentang layanan Google Analytics, silakan klik <span><a className='link-text' target='_blank' href='https://support.google.com/analytics/answer/12159447?hl=en#:~:text=Google%20Analytics%20is%20a%20platform,provide%20insights%20into%20your%20business.'>di sini</a></span><br/><br/>.",
    ],
  },
  {
    title: "Bagaimana Menghubungi Kami",
    description:
      "Untuk mengetahui lebih lanjut tentang pendekatan kami terhadap privasi, silakan baca <span><a className='link-text' target='_blank' href='https://www.asetku.co.id/privacyPolicy'>Kebijakan Privasi</a></span><br/><br/>.",
  },
]

const cookiesContentEn = [
  {
    title: "About Cookies",
    description:
      "Cookies are small text files stored on your computer or device. We use cookies to track user activity to enhance interface services and customer experience. Devices and internet browsers generally use cookies; it is our policy that the use of cookies and tracking technologies is carried out only with the customer's consent for the digital services we provide, except where the use of cookies or tracking technologies is strictly necessary for the operation and use of the services.<br/><br/>This policy explains how AsetKu uses cookies in relation to our operations on the website <span><a className='link-text' target='_blank' href='https://www.asetku.co.id'>www.asetku.co.id</a></span> and all of our websites and applications <strong>(“Services”)</strong>.<br/><br/>We will outline information regarding:<br/>Cookies and online tracking technologies<br/>How we use cookies and online tracking technologies<br/>How to contact us.",
  },
  {
    title: "Cookies",
    description:
      "A cookie is a small text file sent by a website to your computer or device and stored on your web browser. Web browser cookies can store information such as your IP address, browser type, and information related to the content you view or interact with on digital services. By storing this information, web browser cookies can remember your preferences and settings for online services and how you use those services.",
  },
  {
    title: "How We Use Cookies and Online Tracking Technologies",
    description: [
      "We use several cookies and tracking technologies for purposes that are strictly necessary for your use of our Services. These include, but are not limited to, cookies that remember your preferences and settings, retain information you may have entered online (so you don’t have to re-enter it each time you navigate to another page), or keep you logged into the portals you may wish to access.<br/><br/>",
      "With your consent, we may also use cookies and tracking technologies for other purposes, such as creating aggregate statistics about how people use our Services, troubleshooting technical issues, helping you share content with others, enabling you to receive information about other products and services, and enhancing your experience with our Services.<br/><br/>",
      "Strictly Necessary Cookies<br/>These cookies are essential for the core features of our site to function properly. Because they are necessary for the operation of the site, the settings for Strictly Necessary Cookies are always set to “Active.” You can disable them by changing your browser settings, but this may impact the functionality of the website.<br/><br/>",
      "Functional Cookies<br/>These cookies are used to provide a better user experience on our website, such as by measuring interactions with certain content or remembering your settings, like language preferences or video playback options.<br/><br/>",
      "Performance Cookies<br/>These cookies allow us to count visits and traffic sources on our website so we can measure and improve its performance. They help us identify the most and least popular pages and understand how visitors navigate the site. All information collected by these cookies is aggregated and anonymous. If you do not allow these cookies, we will not know when you visit our website and cannot monitor its performance.<br/><br/>",
      "If you have allowed the use of performance cookies, we use Google Analytics to collect statistical information about how our Services are used. The Google Analytics service involves the use of cookies that collect information such as browser data and information about the content you view and interact with to record how you use our Services.<br/><br/>",
      "This analytics service helps us understand how many users we have, which parts of our site are the most popular, which browsers are used (so we can optimize compatibility), the countries or regions where our users are located, and the demographics and interests of our users. This enables us to better understand who is using our Services and improve how we present content on the platform.<br/><br/>",
      "For more information about the Google Analytics service, please click <span><a className='link-text' target='_blank' href='https://support.google.com/analytics/answer/12159447?hl=en#:~:text=Google%20Analytics%20is%20a%20platform,provide%20insights%20into%20your%20business.'>here</a></span><br/><br/>.",
    ],
  },
  {
    title: "How to Contact Us",
    description:
      "To learn more about our approach to privacy, please read our <span><a className='link-text' target='_blank' href='https://www.asetku.co.id/privacyPolicy'>Privacy Policy</a></span><br/><br/>.",
  },
]
